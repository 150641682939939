<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        v-slot="{ card }"
        :cards="cards"
        :title="
          getRes(
            'CustMenu.Com.Action.ViewDownloadResponseToQueriesClarifications'
          )
        "
        hide-count-badge
      >
        <pro-deck-card :title="card.title" expandable>
          <pro-deck-card-time-map :entries="card.queries" />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeckCardTimeMap from "@/components/PROSmart/Deck/Body/ProDeckCardTimeMap";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProDeckCardTimeMap },
  data() {
    return {
      responseToQueries: {
        key: 1,
        title: "Form.Section.QueriesResponse",
        queries: [],
      },
      responseToClarifications: {
        key: 2,
        title: "Form.Section.ResponsetoQueries",
        queries: [],
      },
    };
  },
  computed: {
    cards() {
      return [this.responseToQueries, this.responseToClarifications];
    },
  },
  methods: {
    mapQueries(queries) {
      return queries.map((query) => ({
        key: query.queryId,
        title: query.recipientTenderers.join("; "),
        date: new Date(query.dateIssued),
        action: {
          processId: query.processId,
        },
      }));
    },
  },
  created() {
    this.$proSmart.tender
      .getResponseToQueryClarificationList(this, this.$route.params.id)
      .then((responseToQueryClarificationList) => {
        this.responseToQueries.queries = this.mapQueries(
          responseToQueryClarificationList.responseToQueries
        );
        this.responseToClarifications.queries = this.mapQueries(
          responseToQueryClarificationList.responseToClarifications
        );
      });
  },
};
</script>
